<template>
  <transition name="fade" mode="out-in">
    <div v-if="showLogin">
      <ModalTemplate>
        <template #title>{{ $t('login.title') }}</template>
        <template #subtitle>{{ $t('login.subtitle') }}</template>
        <template #content>
          <form ref="loginForm" :class="[errors && 'has-errors', 'form']">
            <div class="mb-l">
              <InputLabel for="login">{{ $t('login.email-a-number-label') }}</InputLabel>
              <InputText id="login" v-model="username" @invalid="invalidateForm" required />
            </div>
            <div class="mb-l">
              <InputLabel for="password">{{ $t('login.password-label') }}</InputLabel>
              <InputText id="password" type="password" v-model="password" @invalid="invalidateForm" required />
            </div>
            <p class="mb-l red" v-if="showCredentialsError">
              {{ $t('login.incorrect-email-password') }}
            </p>
            <div class="ta-center mb-xl">
              <Button class="mb-m" type="submit" @click="clickLogin($event)">{{ $t('global.login') }}</Button>
              <div class="d-b">
                <button class="fs-14 fw-700 background--transparent grey-dark"
                        @click="forgotPassword"
                        ref="loginForgotPasswordButton"
                        type="button">
                  {{ $t('login.forgot-password-button') }}
                </button>
              </div>
            </div>
            <div class="ta-center">
              <p class="fs-14 grey-dark">{{ $t('login.sign-up-message') }}</p>
              <Button :to="{name: 'Sign up'}" inverse >{{ $t('global.sign-up') }}</Button>
            </div>
          </form>
        </template>
      </ModalTemplate>
    </div>
    <div v-else>
      <ModalTemplate ref="resetPasswordModal">
        <template #title>{{ $t('login.password-reset.title') }}</template>
        <template #subtitle>{{ $t('login.password-reset.instructions') }}</template>
        <template #content>
          <form ref="passwordResetForm" :class="[errors && 'has-errors', 'form']">
            <div class="mb-l">
              <InputLabel for="email">{{ $t('global.email' )}}</InputLabel>
              <InputText id="email" v-model="email" type="email" @invalid="invalidateForm" required></InputText>
            </div>
            <p class="mb-l red" v-if="showEmailError">
              {{ $t('login.password-reset.non-existing-email-error') }}
            </p>
            <div class="ta-center mb-xl">
              <Button @click="clickForgottenPassword($event)" class="mb-m" type="submit">{{ $t('login.password-reset.button') }}</Button>
            </div>
            <div class="ta-center">
              <p class="fs-14 grey-dark">{{ $t('login.sign-up-message') }}</p>
              <Button :to="{name: 'Sign up'}" inverse >{{ $t('global.sign-up') }}</Button>
            </div>
          </form>
        </template>
      </ModalTemplate>
    </div>
  </transition>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'
import { forgottenPassword, login, setToken } from '@/api/user/user-api'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey, LoadingScreenKey, MemberServiceKey, NotificationsServiceKey, PackageServiceKey, SliderServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Login Panel',
  components: {
    LoadingDots,
    Button,
    InputText,
    InputLabel,
    ModalTemplate
  },
  emits: ['show-loading-animation', 'hide-loading-animation']
})
export default class Login extends Vue {
  showLogin = true
  username = 'user4@lightbulb.lu'
  password = '123456'

  email = ''

  isLoading = false
  errors = false
  showCredentialsError = false
  showEmailError = false

  errorHandlerService = inject(ErrorHandlerKey)

  forgotPassword () {
    this.showLogin = false
    this.errors = false
  }

  clickLogin (ev: Event) {
    const form: any = this.$refs['loginForm']
    if (form.checkValidity()) {
      ev.preventDefault()
      this.checkUserLogin()
    }
  }

  checkUserLogin () {
    this.$emit('show-loading-animation')
    login(this.username, this.password)
      .then((response) => {
        setToken(response.data.token, response.data.refreshToken)
        this.loadContent()
      })
      .catch((error) => {
        if (error.response.data.status === 401) {
          this.showLoginError()
        } else {
          this.errorHandlerService?.loadError(error.response.data)
        }
      })
  }

  showLoginError () {
    this.$emit('hide-loading-animation')
    this.showCredentialsError = true
  }

  showPasswordForgetError () {
    this.$emit('hide-loading-animation')
    this.showEmailError = true
  }

  loadingScreenState = inject(LoadingScreenKey)
  notificationsService = inject(NotificationsServiceKey)
  //packageService = inject(PackageServiceKey)
  sliderService = inject(SliderServiceKey)
  membersService = inject(MemberServiceKey)
  inquiriesService = inject(InquiryServiceKey)

  loadContent () {
    this.loadingScreenState?.show()

    Promise.all([
      this.notificationsService?.loadNotificationsPreview(1, 3, true),
      //this.packageService?.loadCurrentPackage(true),
      this.sliderService?.loadSlides(true),
      this.membersService?.loadCurrentMember(true),
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('reimbursed', 1, 20, true),
      this.inquiriesService?.loadInquiries('rejected', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true),
      this.inquiriesService?.loadCategories()
    ]).then(() => {
      this.redirectUser()
      setTimeout(() => this.loadingScreenState?.hide(), 500)
    })
  }

  redirectUser () {
    let redirect = '/dashboard'
    const redirectFrom: any = this.$route.query.redirectFrom
    if (redirectFrom) {
      redirect = redirectFrom
    }
    this.$router.push({ path: redirect })
  }

  clickForgottenPassword (ev: Event) {
    ev.preventDefault()
    const form: any = this.$refs['passwordResetForm']
    if (form.checkValidity()) {
      this.submitForgottenPassword()
    }
  }

  submitForgottenPassword () {
    this.$emit('show-loading-animation')
    forgottenPassword(this.email)
      .then(() => {
        this.$router.push({ name: 'Reset password', query: { token: '123' } })
      })
      .catch((error) => {
        if (error.response.data.status === 404) {
          this.showPasswordForgetError()
        } else {
          this.errorHandlerService?.loadError(error.response.data)
        }
      })
  }

  invalidateForm () {
    this.errors = true
  }
}
</script>
