
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'
import { signup, setToken } from '@/api/user/user-api'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

@Options({
  name: 'Sign-up',
  components: {
    Button,
    InputText,
    InputLabel,
    ModalTemplate,
    IconButton
  }
})
export default class SignUp extends Vue {
  errors = false
  firstStep = true

  firstName = ''
  lastName = ''
  email = ''
  password = ''
  passwordConfirmation = ''

  isLoading = false

  errorHandlerService = inject(ErrorHandlerKey)

  nextStep () {
    this.firstStep = false
  }

  previousStep () {
    this.firstStep = true
  }

  checkPassword () {
    const confirmedPassword: any = this.$refs['confirmPassword']
    if (this.password !== this.passwordConfirmation) {
      confirmedPassword.$el.setCustomValidity('Passwords don\'t match')
    } else {
      confirmedPassword.$el.setCustomValidity('')
    }
  }

  clickSubmit (ev: Event) {
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      this.isLoading = true
      ev.preventDefault()
      this.submitUser()
    } else {
      if (this.firstName === '' || this.lastName === '') {
        this.firstStep = true
      }
    }
  }

  submitUser () {
    const newUser = {
      'firstname': this.firstName,
      'lastname': this.lastName,
      'email': this.email,
      'password': this.password
    }
    signup(newUser)
      .then((response) => {
        setToken(response.data.token, response.data.refreshToken)
        this.redirectUser()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  redirectUser () {
    this.$router.push({ name: 'Dashboard' })
    this.isLoading = false
  }

  invalidateForm () {
    this.errors = true
  }
}
