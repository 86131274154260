<template>
   <ModalTemplate>
     <template #title>Session Expired</template>
     <template #subtitle>Your session expired. Please login again</template>
     <template #content>
       <div class="ta-center mb-xl">
         <Button class="mb-m" type="submit" :to="{ name: 'Login' }">Login</Button>
       </div>
     </template>
   </ModalTemplate>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'

@Options({
  name: 'Expired Token Panel',
  components: {
    Button,
    ModalTemplate
  }
})
export default class Login extends Vue {

}
</script>
<style lang="scss" scoped></style>
