
import { Options, Vue } from 'vue-class-component'
import Logo from '@/assets/logo.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Logged-out',
  components: {
    LoadingDots,
    Logo
  }
})
export default class Login extends Vue {
  isLoading = false

  showLoadingAnimation () {
    this.isLoading = true
  }

  hideLoadingAnimation () {
    this.isLoading = false
  }
}
