
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'
import { forgottenPassword, login, setToken } from '@/api/user/user-api'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey, LoadingScreenKey, MemberServiceKey, NotificationsServiceKey, PackageServiceKey, SliderServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Login Panel',
  components: {
    LoadingDots,
    Button,
    InputText,
    InputLabel,
    ModalTemplate
  },
  emits: ['show-loading-animation', 'hide-loading-animation']
})
export default class Login extends Vue {
  showLogin = true
  username = 'user4@lightbulb.lu'
  password = '123456'

  email = ''

  isLoading = false
  errors = false
  showCredentialsError = false
  showEmailError = false

  errorHandlerService = inject(ErrorHandlerKey)

  forgotPassword () {
    this.showLogin = false
    this.errors = false
  }

  clickLogin (ev: Event) {
    const form: any = this.$refs['loginForm']
    if (form.checkValidity()) {
      ev.preventDefault()
      this.checkUserLogin()
    }
  }

  checkUserLogin () {
    this.$emit('show-loading-animation')
    login(this.username, this.password)
      .then((response) => {
        setToken(response.data.token, response.data.refreshToken)
        this.loadContent()
      })
      .catch((error) => {
        if (error.response.data.status === 401) {
          this.showLoginError()
        } else {
          this.errorHandlerService?.loadError(error.response.data)
        }
      })
  }

  showLoginError () {
    this.$emit('hide-loading-animation')
    this.showCredentialsError = true
  }

  showPasswordForgetError () {
    this.$emit('hide-loading-animation')
    this.showEmailError = true
  }

  loadingScreenState = inject(LoadingScreenKey)
  notificationsService = inject(NotificationsServiceKey)
  //packageService = inject(PackageServiceKey)
  sliderService = inject(SliderServiceKey)
  membersService = inject(MemberServiceKey)
  inquiriesService = inject(InquiryServiceKey)

  loadContent () {
    this.loadingScreenState?.show()

    Promise.all([
      this.notificationsService?.loadNotificationsPreview(1, 3, true),
      //this.packageService?.loadCurrentPackage(true),
      this.sliderService?.loadSlides(true),
      this.membersService?.loadCurrentMember(true),
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('reimbursed', 1, 20, true),
      this.inquiriesService?.loadInquiries('rejected', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true),
      this.inquiriesService?.loadCategories()
    ]).then(() => {
      this.redirectUser()
      setTimeout(() => this.loadingScreenState?.hide(), 500)
    })
  }

  redirectUser () {
    let redirect = '/dashboard'
    const redirectFrom: any = this.$route.query.redirectFrom
    if (redirectFrom) {
      redirect = redirectFrom
    }
    this.$router.push({ path: redirect })
  }

  clickForgottenPassword (ev: Event) {
    ev.preventDefault()
    const form: any = this.$refs['passwordResetForm']
    if (form.checkValidity()) {
      this.submitForgottenPassword()
    }
  }

  submitForgottenPassword () {
    this.$emit('show-loading-animation')
    forgottenPassword(this.email)
      .then(() => {
        this.$router.push({ name: 'Reset password', query: { token: '123' } })
      })
      .catch((error) => {
        if (error.response.data.status === 404) {
          this.showPasswordForgetError()
        } else {
          this.errorHandlerService?.loadError(error.response.data)
        }
      })
  }

  invalidateForm () {
    this.errors = true
  }
}
