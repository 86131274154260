
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { checkQrToken, postQrFile } from '@/api/uploads/qr-api'
import Icon from '@/01-atoms/Icon.vue'

@Options({
  name: 'Login Panel',
  components: {
    Icon,
    LoadingDots,
    Button,
    ModalTemplate
  },
  emits: ['show-loading-animation', 'hide-loading-animation']
})
export default class Login extends Vue {
  hasUploaded = false

  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.$emit('show-loading-animation')
    this.checkQrTokenValidity()
  }

  checkQrTokenValidity () {
    const token = this.$route.params.token.toString()
    checkQrToken(token)
      .then((response) => {
        if (response.data['isValid']) {
          setTimeout(() => {
            this.$emit('hide-loading-animation')
          }, 1000)
        } else {
          const error = {
            'title': 'Invalid token',
            'detail': 'We received an invalid token. Please scan your QR code again',
            'showGlobalMessage': false
          }
          this.errorHandlerService?.loadError(error)
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  createFile () {
    this.$emit('show-loading-animation')

    const input: any = this.$refs['upload-input']
    const token = this.$route.params.token.toString()

    const file = new FormData()
    file.append('title', token)
    file.append('file', input['files'][0])
    const type: any = this.$route.params.type
    file.append('fileType', type)

    this.uploadFile(token, file)
  }

  uploadFile (token: string, file: any) {
    postQrFile(token, file)
      .then((response) => {
        this.hasUploaded = true
        this.$emit('hide-loading-animation')
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
