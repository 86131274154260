<template>
  <form :class="[errors && 'has-errors', 'form', isLoading && 'is-loading']" ref="form">
    <ModalTemplate>
      <template #title>{{ $t('sign-up.title')}}</template>
      <template #subtitle>{{ $t('sign-up.subtitle')}}</template>
      <template #content>
        <transition name="fade" mode="out-in">
          <div>
            <div v-show="firstStep" key="signUpFirstStep" ref="signUpFirstStep">
              <div class="mb-l">
                <InputLabel for="first-name">{{ $t('global.first-name') }}</InputLabel>
                <InputText v-model="firstName" id="first-name" @invalid="invalidateForm" required></InputText>
              </div>
              <div class="mb-l">
                <InputLabel for="family-name">{{ $t('global.last-name') }}</InputLabel>
                <InputText v-model="lastName" id="family-name" @invalid="invalidateForm" required></InputText>
              </div>
              <div class="ta-center mb-xl">
                <Button @click="nextStep" class="mb-m" ref="signUpNextButton" >{{ $t('global.next') }}</Button>
              </div>
            </div>
            <div v-show="!firstStep" key="signUpSecondStep" ref="signUpSecondStep">
              <div class="mb-l">
                <InputLabel for="email">{{ $t('global.email') }}</InputLabel>
                <InputText v-model="email" id="email" type="email" @invalid="invalidateForm" required></InputText>
              </div>
              <div class="mb-l">
                <InputLabel for="password">{{ $t('global.enter-password') }}</InputLabel>
                <InputText v-model="password"
                           id="password"
                           type="password"
                           ref="mainPassword"
                           minlength="12"
                           @invalid="invalidateForm"
                           required />
              </div>
              <div class="mb-l">
                <InputLabel for="password-confirmation">{{ $t('global.confirm-password') }}</InputLabel>
                <InputText v-model="passwordConfirmation"
                           id="password-confirmation"
                           type="password"
                           ref="confirmPassword"
                           @change="checkPassword"
                           minlength="12"
                           @invalid="invalidateForm"
                           required />
                <span style="display: none">{{ $t('global.password-not-matching')}}</span>
              </div>
              <div class="mb-xl signUp__actions">
                <IconButton icon="arrow-left" class="mb-m" @click="previousStep" ref="signUpPreviousButton" background="transparent" >{{ $t('global.back') }}</IconButton>
                <Button @click="clickSubmit($event)" class="mb-m" type="submit" >{{ $t('global.submit') }}</Button>
              </div>
            </div>
          </div>
        </transition>
        <div class="ta-center">
          <p class="fs-14 grey-dark">{{ $t('sign-up.login-message') }}</p>
          <Button :to="{name: 'Login'}" inverse >{{ $t('global.login') }}</Button>
        </div>
      </template>
    </ModalTemplate>
  </form>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'
import { signup, setToken } from '@/api/user/user-api'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

@Options({
  name: 'Sign-up',
  components: {
    Button,
    InputText,
    InputLabel,
    ModalTemplate,
    IconButton
  }
})
export default class SignUp extends Vue {
  errors = false
  firstStep = true

  firstName = ''
  lastName = ''
  email = ''
  password = ''
  passwordConfirmation = ''

  isLoading = false

  errorHandlerService = inject(ErrorHandlerKey)

  nextStep () {
    this.firstStep = false
  }

  previousStep () {
    this.firstStep = true
  }

  checkPassword () {
    const confirmedPassword: any = this.$refs['confirmPassword']
    if (this.password !== this.passwordConfirmation) {
      confirmedPassword.$el.setCustomValidity('Passwords don\'t match')
    } else {
      confirmedPassword.$el.setCustomValidity('')
    }
  }

  clickSubmit (ev: Event) {
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      this.isLoading = true
      ev.preventDefault()
      this.submitUser()
    } else {
      if (this.firstName === '' || this.lastName === '') {
        this.firstStep = true
      }
    }
  }

  submitUser () {
    const newUser = {
      'firstname': this.firstName,
      'lastname': this.lastName,
      'email': this.email,
      'password': this.password
    }
    signup(newUser)
      .then((response) => {
        setToken(response.data.token, response.data.refreshToken)
        this.redirectUser()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  redirectUser () {
    this.$router.push({ name: 'Dashboard' })
    this.isLoading = false
  }

  invalidateForm () {
    this.errors = true
  }
}
</script>
<style lang="scss" scoped>
.signUp__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
</style>
