<template>
  <div class="modal_container">
    <router-link :to="{name: 'Login'}" class="modal__media">
      <Logo class="modal__logo"></Logo>
    </router-link>
    <div class="modal__box">
      <div v-if="isLoading" class="modal__loading-animation">
        <LoadingDots />
      </div>
      <div :class="[isLoading && 'is-loading']">
        <router-view @show-loading-animation="showLoadingAnimation" @hide-loading-animation="hideLoadingAnimation" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Logo from '@/assets/logo.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Logged-out',
  components: {
    LoadingDots,
    Logo
  }
})
export default class Login extends Vue {
  isLoading = false

  showLoadingAnimation () {
    this.isLoading = true
  }

  hideLoadingAnimation () {
    this.isLoading = false
  }
}
</script>
<style lang="scss" scoped>
.modal_container {
  background-color: $grey-light-5;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 15px 0;
  width: 100%;

  @include breakpoint(medium down) {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 15px 0;
  }
}

.modal__media {
  margin: 0 auto;
  max-width: 360px;
  padding: 0 60px 30px;
  width: 100%;

  @include breakpoint(medium down) {
    padding: 15px 60px 15px;
    width: 80%;
  }
}

.modal__logo {
  height: 100%;
  width: 100%;
}

.modal__box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 2px $grey-dark-10;
  margin: 0 auto 15px;
  max-width: 700px;
  padding: 38px;
  position: relative;
  width: 100%;

  @include breakpoint(medium down) {
    padding: 38px 15px;
  }
}

.modal__loading-animation {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
