<template>
  <ModalHeader v-if="!confirmation">
    <template #title>Forgot your password?</template>
    <template #subtitle>Create a new password</template>
    <template #content>
      <form :class="[errors && 'has-errors', isLoading && 'is-loading', 'form']" ref="form">
        <div class="mb-l">
          <InputLabel for="password">Enter Password</InputLabel>
          <InputText
            id="password"
            v-model="password"
            @invalid="invalidateForm"
            type="password"
            minlength="12"
            required/>
        </div>
        <div class="mb-l">
          <InputLabel for="password">Confirm Password</InputLabel>
          <InputText
            id="password"
            @change="checkPassword"
            v-model="passwordConfirmation"
            @invalid="invalidateForm"
            ref="confirmPassword"
            type="password"
            minlength="12"
            required/>
        </div>
        <div class="ta-center">
          <Button @click="clickResetPassword($event)" class="mb-m" type="submit">Reset password</Button>
        </div>
      </form>
    </template>
  </ModalHeader>
  <ModalHeader v-else>
    <template #title>Success!</template>
    <template #subtitle>You have successfully changed your password.</template>
    <template #content>
      <div class="mb-xl">
        <Icon icon="check-circle" class="blue-dark confirmation__icon" />
        <p class="ta-center display-3">You can now login with your new password</p>
      </div>
      <div class="ta-center">
        <Button :to="{ name: 'Login' }" class="mb-m" type="submit">Login</Button>
      </div>
    </template>
  </ModalHeader>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import ModalHeader from '@/03-organisms/Modal-template.vue'
import { resetPassword } from '@/api/user/user-api'
import Icon from '@/01-atoms/Icon.vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Reset-password',
  components: {
    Button,
    InputText,
    InputLabel,
    ModalHeader,
    Icon
  }
})
export default class ResetPassword extends Vue {
  errors = false
  password = ''
  passwordConfirmation = ''
  isLoading = false
  confirmation = false

  errorHandlerService = inject(ErrorHandlerKey)

  clickResetPassword (ev: Event) {
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      ev.preventDefault()
      this.submitForm()
    }
  }

  submitForm () {
    this.isLoading = true
    const token = this.$route.query.token
    resetPassword(token, this.password)
      .then(() => {
        this.isLoading = false
        this.confirmation = true
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  checkPassword () {
    const confirmedPassword: any = this.$refs['confirmPassword']
    if (this.password !== this.passwordConfirmation) {
      confirmedPassword.$el.setCustomValidity('Passwords don\'t match')
    } else {
      confirmedPassword.$el.setCustomValidity('')
    }
  }

  invalidateForm () {
    this.errors = true
  }
}
</script>
<style lang="scss" scoped>
  .confirmation__icon {
    height: 85px;
    margin-right: auto;
    margin-left: auto;
    width: 85px;
  }
</style>
