
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import ModalHeader from '@/03-organisms/Modal-template.vue'
import { resetPassword } from '@/api/user/user-api'
import Icon from '@/01-atoms/Icon.vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Reset-password',
  components: {
    Button,
    InputText,
    InputLabel,
    ModalHeader,
    Icon
  }
})
export default class ResetPassword extends Vue {
  errors = false
  password = ''
  passwordConfirmation = ''
  isLoading = false
  confirmation = false

  errorHandlerService = inject(ErrorHandlerKey)

  clickResetPassword (ev: Event) {
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      ev.preventDefault()
      this.submitForm()
    }
  }

  submitForm () {
    this.isLoading = true
    const token = this.$route.query.token
    resetPassword(token, this.password)
      .then(() => {
        this.isLoading = false
        this.confirmation = true
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  checkPassword () {
    const confirmedPassword: any = this.$refs['confirmPassword']
    if (this.password !== this.passwordConfirmation) {
      confirmedPassword.$el.setCustomValidity('Passwords don\'t match')
    } else {
      confirmedPassword.$el.setCustomValidity('')
    }
  }

  invalidateForm () {
    this.errors = true
  }
}
