
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'

@Options({
  name: 'Expired Token Panel',
  components: {
    Button,
    ModalTemplate
  }
})
export default class Login extends Vue {

}
