<template>
    <ModalTemplate>
      <template #title>{{ $t('qr-file-upload.title') }}</template>
      <template #subtitle>{{ $t('qr-file-upload.subtitle') }}</template>
      <template #content>
        <div v-if="!hasUploaded">
          <div class="mb-l ta-center">
            <label class="qr-file-upload__label" for="qr-file-upload">
              {{ $t('quick-inquiry.upload-button') }}
            </label>
            <input ref="upload-input" id="qr-file-upload" type="file" class="qr-file-upload__input" @change="createFile">
          </div>
        </div>
        <div v-else>
          <div class="mb-l ta-center">
            <p class="display-3">{{ $t('qr-file-upload.success-message') }}</p>
            <p>{{ $t('qr-file-upload.success-description') }}</p>
          </div>
        </div>
      </template>
    </ModalTemplate>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import ModalTemplate from '@/03-organisms/Modal-template.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { checkQrToken, postQrFile } from '@/api/uploads/qr-api'
import Icon from '@/01-atoms/Icon.vue'

@Options({
  name: 'Login Panel',
  components: {
    Icon,
    LoadingDots,
    Button,
    ModalTemplate
  },
  emits: ['show-loading-animation', 'hide-loading-animation']
})
export default class Login extends Vue {
  hasUploaded = false

  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.$emit('show-loading-animation')
    this.checkQrTokenValidity()
  }

  checkQrTokenValidity () {
    const token = this.$route.params.token.toString()
    checkQrToken(token)
      .then((response) => {
        if (response.data['isValid']) {
          setTimeout(() => {
            this.$emit('hide-loading-animation')
          }, 1000)
        } else {
          const error = {
            'title': 'Invalid token',
            'detail': 'We received an invalid token. Please scan your QR code again',
            'showGlobalMessage': false
          }
          this.errorHandlerService?.loadError(error)
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  createFile () {
    this.$emit('show-loading-animation')

    const input: any = this.$refs['upload-input']
    const token = this.$route.params.token.toString()

    const file = new FormData()
    file.append('title', token)
    file.append('file', input['files'][0])
    const type: any = this.$route.params.type
    file.append('fileType', type)

    this.uploadFile(token, file)
  }

  uploadFile (token: string, file: any) {
    postQrFile(token, file)
      .then((response) => {
        this.hasUploaded = true
        this.$emit('hide-loading-animation')
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
</script>
<style lang="scss" scoped>
.qr-file-upload__label {
  @include brandon-grotesque-bold;
  appearance: none;
  background: $blue-dark;
  border: none;
  border-radius: 30px;
  color: $white;
  display: inline-block;
  font-size: 14px;
  font-family: sans-serif;
  padding: 8px 23px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.qr-file-upload__input {
  position: absolute;
  opacity: 0;
  width: 0;
}

.qr-icon {
  height: 26px;
  flex-shrink: 0;
  width: 26px;
}
</style>
